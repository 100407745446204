import React from 'react'
import { Container, Row, Col, Accordion } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import img1 from '../images/module-ellipse.webp'
import icon1 from '../images/Coding.webp'
import icon2 from '../images/Contactless.webp'
import icon3 from '../images/Shoppingbag.webp'
import icon4 from '../images/mod1.webp'
import icon5 from '../images/mod2.webp'
import icon6 from '../images/mod3.webp'
import icon7 from '../images/mod4.webp'
import icon8 from '../images/cms.png'
import { useSelector, useDispatch } from 'react-redux';
import { setMenuTrue, setToggleTrue } from '../redux/slice/changeState'
import Contact from './Contact';
import { FaCheck } from "react-icons/fa6";

const Modules = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const toggleMenu = useSelector((state) => state.toggle)
    const dispatch = useDispatch()
    return (
        <>
            <div className='module-section position-relative' id="moduler">
                <Container>
                    <div className='text-center'>
                        <h5 className='primary-text'>Powerful Modules for Every Business</h5>
                        <h2>Smart Integrated Solutions Designed to Enhance Business Efficiency and Scalability</h2>
                        {isMobile ?
                            <h6>Shopersbay empowers businesses to manage every aspect of their operations with a robust suite of e-commerce modules:</h6>
                            :
                            <>
                                <h6>Shopersbay stands out as a leader in <b>business management software</b>, combining cutting-edge technology with easy-to-use tools.</h6>

                            </>
                        }

                    </div>
                    <Accordion defaultActiveKey="0" className='mt-5'>
                        <Row>
                            <Col lg={6}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <img src={icon3} alt="" className='img-fluid icon'></img>
                                        <div className='d-flex justify-content-between w-100'>
                                            <div className="accordion-header-content">
                                                <h5>E-Commerce</h5>
                                                <p>Personalized solution with customizable storefronts and a curated app collection</p>
                                            </div>
                                            <div className="accordion-icon"></div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className='list-style-disc'>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Product, Inventory and Order Management	</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Efficient Logistics Management </li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Customizable Storefronts and Mobile Apps</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Promotions, Product Bundles and Add-ons</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Integrated Payment Gateway</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className='border-0'>
                                        <img src={icon2} alt="" className='img-fluid icon'></img>
                                        <div className='d-flex justify-content-between w-100'>
                                            <div className="accordion-header-content">
                                                <h5>POS System for Retailers</h5>
                                                <p>Process sales efficiently, whether online or in-store.</p>
                                            </div>
                                            <div className="accordion-icon"></div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className='list-style-disc'>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Real-Time Sales and Inventory Syncing	</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Multi-Location Warehouse Management </li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Efficient Counter Operations</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Advanced Reporting and Analytics</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Integrated Payment Solutions</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <img src={icon5} alt="" className='img-fluid icon'></img>
                                        <div className='d-flex justify-content-between w-100'>
                                            <div className="accordion-header-content">
                                                <h5>CRM</h5>
                                                <p>Build lasting relationships with advanced tools.</p>
                                            </div>
                                            <div className="accordion-icon"></div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className='list-style-disc'>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Customer Segmentation</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Account and Contract Management </li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Lead-to-Sales Pipeline Automation</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> In-Store Integration</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Real-Time Ticket Management</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        <img src={icon8} alt="" className='img-fluid icon'></img>
                                        <div className='d-flex justify-content-between w-100'>
                                            <div className="accordion-header-content">
                                                <h5>CMS</h5>
                                                <p>Streamline, Manage, and Deliver Content with Ease</p>
                                            </div>
                                            <div className="accordion-icon"></div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className='list-style-disc'>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> User-Friendly Editor for Content Organization and Management</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Customization and Templates </li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Content Workflow and Collaboration
                                            </li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Media and Asset Management</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} />Multilingual and Multi-Site Support</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Col>
                            <Col lg={6}>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header className='' >
                                        <img src={icon4} alt="" className='img-fluid icon'></img>
                                        <div className='d-flex justify-content-between w-100'>
                                            <div className="accordion-header-content">
                                                <h5>Accounts Management Software</h5>
                                                <p>Simplify your financial processes with real-time tracking.</p>
                                            </div>
                                            <div className="accordion-icon"></div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className='list-style-disc'>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Invoicing, Billing & Expense Tracking</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Real-Time Financial Reporting Analytics and Insights,</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Client and Vendor Management</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Integrated Ecosystem</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Seamless Scalability & Superior User Experience</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                        <img src={icon1} alt="" className='img-fluid icon'></img>
                                        <div className='d-flex justify-content-between w-100'>
                                            <div className="accordion-header-content">
                                                <h5>HRIS Software</h5>
                                                <p>Manage your workforce, payroll, and attenda.</p>
                                            </div>
                                            <div className="accordion-icon"></div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className='list-style-disc'>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Employee Data Management	</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Payroll and Benefits Administration </li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Recruitment and Onboarding</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Time and Attendance Tracking</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Performance Management</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>
                                        <img src={icon6} alt="" className='img-fluid icon'></img>
                                        <div className='d-flex justify-content-between w-100'>
                                            <div className="accordion-header-content">
                                                <h5>Assets Management Tools</h5>
                                                <p>Optimize asset tracking and utilization.</p>
                                            </div>
                                            <div className="accordion-icon"></div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className='list-style-disc'>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Asset Tracking and Monitoring</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Maintenance Management </li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Asset Depreciation and Financial Reporting</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Inventory Management and Procurement</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Compliance and Regulatory Tracking</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>
                                        <img src={icon7} alt="" className='img-fluid icon'></img>
                                        <div className='d-flex justify-content-between w-100'>
                                            <div className="accordion-header-content">
                                                <h5>Audit Tools</h5>
                                                <p>Ensuring Accuracy, Transparency, and Compliance Every Step of the Way.</p>
                                            </div>
                                            <div className="accordion-icon"></div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className='list-style-disc'>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Automated Data Collection and Analysis	</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Real-Time Reporting and Dashboards </li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Risk Assessment and Control Testing</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Collaboration and Workflow Management</li>
                                            <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Audit Trail and Compliance Tracking</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Col>
                        </Row>
                    </Accordion>
                    <div className='text-center mt-5'>
                        <button type="submit" className='common-btn' onClick={() => dispatch(setToggleTrue())}>Get In Touch</button>
                    </div>
                </Container>
                {!isMobile &&
                    <img src={img1} alt="module" className='img-fluid position-absolute end-0 bottom-0'></img>
                }
            </div>
            {
                toggleMenu.hastoggle &&
                <Contact />
            }
        </>
    )
}

export default Modules
