import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import bg from '../images/why.webp'
import { BsPuzzle } from "react-icons/bs";
import { MdOutlineShoppingCart } from "react-icons/md";
import { IoIosApps } from "react-icons/io";
import { MdOutlineSupportAgent } from "react-icons/md";

const Why = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const data = [
        {
            img: <MdOutlineShoppingCart fill="#fff" size={40} />,
            title: "Comprehensive E-Commerce Modules",
            description: "Simplify your business with an all-in-one platform for Accounts, POS, CRM, HRIS, and beyond.",
        },
        {
            img: <BsPuzzle fill="#fff" size={40} />,
            title: "Scalable Solutions",
            description: "Whether you're a small business or a large enterprise, our platform grows with you, offering flexible options for any size operation.",
        },
        {
            img: <IoIosApps fill="#fff" size={40} />,
            title: "Tailored Plans for Every Stage",
            description: "Select the optimal combination of tools, meticulously tailored to support your business through every phase of growth.",
        },
        {
            img: <MdOutlineSupportAgent fill="#fff" size={40} />,
            title: "24/7 Expert Support",
            description: "Enjoy peace of mind with round-the-clock assistance, ensuring you have the guidance you need whenever you need it.",
        }
    ]
    return (
        <div className='why-section '>
            <Container>
                <div className='text-center'>
                    <h5 className='primary-text'>Why Choose Shopersbay for Your Business?</h5>
                    <h2>Your All-In-One Solution for E-commerce Success</h2>
                    <h6>Shopersbay leads the way in <b>e-commerce management software</b>, blending innovative technology with user-friendly tools for seamless business operations.</h6>
                </div>
                <Row className={` ${isMobile ? "mt-2": "mt-5"}`}>
                        <Col lg={6} className='text-center'>
                            <div><img src={bg} alt="" className='img-fluid w-100'></img></div>
                            {/* <button className='common-btn'>Why Shopersbay?</button> */}
                        </Col>
                       
                        <Col lg={6} >
                            <ul className=''>
                                {data.map((ele, i) => {
                                    return (
                                        <li className="feature-item" key={i}>
                                            <Row className={`w-100`}>
                                                        <div className={`col-lg-2 col-md-3 col-3 ${isMobile ? "" : "d-flex align-items-center justify-content-end"} `}>
                                                            <div className="img-icon">{ele.img}</div>
                                                        </div>
                                                        <div className='col-lg-10 col-md-9 col-9' style={{paddingRight: "0px"}}>
                                                            <div>
                                                                <h5>{ele.title}</h5>
                                                                <p>{ele.description}</p>
                                                            </div>
                                                        </div>

                                                    </Row>
                                            
                                            
                                        </li>
                                    )
                                })}

                            </ul>
                            
                        </Col>
                        
                    

                    
                    
                </Row>
            </Container>
        </div>
    )
}

export default Why
