import React, {useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import img1 from '../images/client.webp'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay } from 'swiper/modules';
import {fetchImagesData} from '../redux/slice/onLoad'
import { useDispatch, useSelector } from 'react-redux';


const Client = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const dispatch = useDispatch()
    const {imagesList} = useSelector(state=>state.master)

    const handleSignUp = () => {
        window.open("https://login.shopersbay.com/module?q=shopersbay", "_blank");
    }
    
    useEffect(()=>{
        dispatch(fetchImagesData())
    },[])


    return (
        <>
            <div className='client-section background-primary position-relative'>
                <Container>
                    <Row>
                        <Col lg={6} md={12}>
                            <div className='client-text'>
                                <h5 className='primary-text'>Our Clients</h5>
                                <h2>Trusted by Businesses Worldwide</h2>
                                <h6 className='mb-4'>Businesses across industries trust Shopersbay to <b>streamline operations</b> and <b>accelerate growth</b>. From <b>increasing sales</b> with our online storefront and POS system to enhancing efficiency with our intuitive admin panel, Shopersbay is the preferred solution for companies ready to scale.</h6>
                                <div className='client-logos'>
                                    <Swiper
                                        slidesPerView={3}
                                        spaceBetween={30}
                                  
                                        loop={true}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false
                                        }}
                                        modules={[Autoplay]}
                                        className="mySwiper"
                                    >
                                        {imagesList != null && 
                                            imagesList.map((ele, i) => {
                                                return (
                                                    <SwiperSlide key={i}>
                                                        <img src={`client/${ele.image}`} alt={ele.alt} className='img-fluid'></img></SwiperSlide>
                                                )
                                            })
                                        }

                                    </Swiper>
                                </div>

                                <button type="submit" className='common-btn mt-4' onClick={handleSignUp}>Get Started</button>
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            {isMobile &&
                                <img src={img1} alt="" className='img-fluid '></img>
                            }
                        </Col>
                    </Row>
                </Container>
                {!isMobile &&
                    <img src={img1} alt="" className='img-fluid position-absolute client-banner'></img>
                }
            </div>
        </>
    )
}

export default Client
